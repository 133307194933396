/* eslint-disable no-use-before-define */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import {
  Button, Card, Grid, Typography,
} from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

function MobilePopularRollTemplate(props) {
  const { edges: posts } = props.data.allMarkdownRemark;

  return (
    <div>
      {posts
        && posts.map(({ node: post }) => {
          // eslint-disable-next-line max-len
          const fullWidthImage = getImage(post.frontmatter.featuredimage) || post.frontmatter.featuredimage;
          return (
            <Card elevation={1} sx={{ width: '100%' }}>
              <Grid container direction="column">
                <Grid item>
                  <Link to={post.frontmatter.link}>
                    <GatsbyImage image={fullWidthImage} />
                  </Link>
                </Grid>

                <Grid item xs={12} sm={7}>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    rowGap={3}
                    sx={{
                      pt: 2,
                      pb: 4,
                      px: 4,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '21px',
                        letterSpacing: '0px',
                        textAlign: 'left',
                        color: '#6F707A',
                      }}
                    >
                      {post.frontmatter.date}
                    </Typography>
                    <Link to={post.frontmatter.link}>
                      <Typography
                        sx={{
                          fontFamily: 'Space Grotesk',
                          fontSize: '24px',
                          fontWeight: '400',
                          lineHeight: '31px',
                          letterSpacing: '0px',
                          textAlign: 'left',
                          color: 'black',
                        }}
                      >
                        {post.frontmatter.title}
                      </Typography>
                    </Link>
                    <Typography
                      sx={{
                        fontFamily: 'Space Grotesk',
                        fontSize: '19px',
                        fontWeight: '400',
                        lineHeight: '29px',
                        letterSpacing: '0px',
                        textAlign: 'left',
                        color: '#6F707A',
                      }}
                    >
                      {post.frontmatter.source}
                    </Typography>
                    <Link to={post.frontmatter.link}>
                      <Button
                        variant="outlined"
                        sx={{
                          color: '#5EBC67',
                          borderColor: '#5EBC67',
                          height: '70px',
                          fontFamily: 'Open Sans',
                          fontSize: '16px',
                          fontWeight: '700',
                          lineHeight: '22px',
                          letterSpacing: '0px',
                          textAlign: 'center',
                          textTransform: 'none',
                          padding: '1vh 3vw',
                        }}
                      >
                        View More →
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          );
        })}
    </div>
  );
}

MobilePopularRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function MobilePopularRoll() {
  return (
    <StaticQuery
      query={graphql`
        query MobilePopularRollQuery {
          allMarkdownRemark(
            limit: 1
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "news-link" }, tags: { in: ["popular"] } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 200)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  source
                  link
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(height: 650, quality: 100, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <MobilePopularRollTemplate data={data} count={count} />}
    />
  );
}
