import React from 'react';

import {
  Button, Container, Card, CardMedia, Grid, TextField, Typography,
} from '@mui/material';
import { BlogJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';
import { Link } from 'gatsby';
import Layout, { LayoutContext } from '../../components/Layout';
import BlogRoll from '../../components/BlogRoll';
import blogPics from '../../img/blog-pics.png';
import blogFrame from '../../img/blog-frame.svg';
import endBlogFrame from '../../img/end-blog-frame.png';
import blogWave from '../../img/blog-wave.svg';
import golfTee from '../../img/golf-tee.jpg';
import NewsRoll from '../../components/NewsRoll';
import PopularRoll from '../../components/PopularRoll';
import MobilePopularRoll from '../../components/MobilePopularRoll';

function BlogPage() {
  const { mobileMode } = React.useContext(LayoutContext);
  return (
    <>
      <Container maxWidth="none" sx={{ backgroundColor: '#5EBC6721' }}>
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            columnGap={2}
            rowGap={3}
            sx={mobileMode ? { py: 2 } : { pt: 8, pb: 4 }}
            direction={mobileMode ? 'column' : 'row'}
          >
            <Grid item xs="12" md="6">
              <Grid container direction="column" rowGap={2}>
                <Typography
                  variant="h1"
                  sx={
                    mobileMode
                      ? {
                        fontFamily: 'Poppins',
                        fontSize: '60px',
                        fontWeight: '500',
                        lineHeight: '80px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                      }
                      : {
                        fontFamily: 'Poppins',
                        fontSize: '68px',
                        fontWeight: '500',
                        lineHeight: '102px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                      }
                  }
                >
                  Golf News and Blog Posts
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Space Grotesk',
                    fontSize: '24px',
                    fontWeight: '400',
                    lineHeight: '35px',
                    letterSpacing: '0px',
                    textAlign: 'left',
                  }}
                >
                  Follow Squaddie to get the latest Golf News curated by our staff and the new
                  features we will be releasing.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs="12" md="5">
              <img src={blogPics} alt="Photos from the Blog" />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container maxWidth="lg" sx={mobileMode ? { py: 2 } : { pt: 6 }}>
        <Typography
          variant="h3"
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '39px',
            fontWeight: '600',
            lineHeight: '48px',
            letterSpacing: '0px',
            textAlign: 'left',
            mb: 4,
          }}
        >
          Popular News
        </Typography>
        {mobileMode ? <MobilePopularRoll /> : <PopularRoll />}
      </Container>
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <Typography
          variant="h3"
          sx={{
            fontFamily: 'Montserrat',
            fontSize: '39px',
            fontWeight: '600',
            lineHeight: '48px',
            letterSpacing: '0px',
            textAlign: 'left',
            mb: 4,
          }}
        >
          Recent News
        </Typography>
        <Grid container direction="column" alignItems="center" sx={{ mt: 4 }} rowGap={6}>
          <NewsRoll />
          <Link to="/news">
            <Button
              variant="contained"
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'center',
                width: '230px',
                height: '60px',
                borderRadius: '10px',
                backgroundColor: '#5AB862',
              }}
            >
              Show More News
            </Button>
          </Link>
        </Grid>
      </Container>
      <Container
        maxWidth="none"
        sx={{ backgroundImage: `url(${blogFrame})`, height: '135px', backgroundSize: 'cover' }}
      >
        <Container maxWidth="lg" sx={{ height: '100%' }}>
          <Grid container alignItems="center" sx={{ height: '100%' }}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '39px',
                fontWeight: '600',
                lineHeight: '48px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: 'white',
              }}
            >
              Squaddie Blogs
            </Typography>
          </Grid>
        </Container>
      </Container>
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center" sx={{ mt: 6 }}>
          <BlogRoll />
          <Link to="/blogs">
            <Button
              variant="contained"
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '24px',
                letterSpacing: '0px',
                textAlign: 'center',
                width: '230px',
                height: '60px',
                borderRadius: '10px',
                backgroundColor: '#5AB862',
              }}
            >
              Show More Blogs
            </Button>
          </Link>
        </Grid>
      </Container>
      <img
        src={blogWave}
        style={{ width: '100vw' }}
        alt="Background element that looks like fairway"
      />
      <Container maxWidth="none" sx={{ backgroundColor: '#4A7B4C', mt: -1 }}>
        <Container maxWidth="lg">
          <Grid container sx={mobileMode ? { pb: 6 } : { pb: 10 }} alignItems="center" rowGap={4}>
            <Grid item xs={12} md={6}>
              <Grid container rowGap={3} sx={{ pr: 3 }} justifyContent="center">
                <Typography
                  variant="h4"
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: '36px',
                    fontWeight: '600',
                    lineHeight: '48px',
                    letterSpacing: '0.20000000298023224px',
                    textAlign: mobileMode ? 'center' : 'left',
                    color: 'white',
                  }}
                >
                  Get our stories delivered to your inbox weekly.
                </Typography>
                <Grid container columnGap={1} rowGap={3} sx={mobileMode && { maxWidth: '60vw' }}>
                  <Grid item xs="12" md="8">
                    <TextField
                      placeholder="Your Email"
                      fullWidth
                      sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                    />
                  </Grid>
                  <Grid item xs="12" md="3.5">
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '18px',
                        fontWeight: '700',
                        lineHeight: '25px',
                        letterSpacing: '0px',
                        textAlign: 'center',
                        color: 'white',
                        backgroundColor: '#5EBC67',
                        height: '56px',
                        textTransform: 'none',
                        borderRadius: '8px',
                        width: '100%',
                      }}
                    >
                      Get Started
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                sx={{
                  backgroundImage: `url(${endBlogFrame})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100%',
                }}
                justifyContent="center"
              >
                <Card
                  sx={{
                    borderRadius: '16px',
                    p: 1,
                    width: '460px',
                    minHeight: '400px',
                  }}
                >
                  <CardMedia
                    sx={{ height: 200, borderRadius: '16px' }}
                    image={golfTee}
                    title="Golf Tee"
                  />
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    sx={{ minHeight: '200px', py: 3, px: 2 }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontFamily: 'Hanken Grotesk',
                        fontSize: '24px',
                        fontWeight: '700',
                        lineHeight: '32px',
                        letterSpacing: '0.20000000298023224px',
                        textAlign: 'left',
                      }}
                    >
                      The best articles every week
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Open Sans',
                        fontSize: '18px',
                        fontWeight: '400',
                        lineHeight: '32px',
                        letterSpacing: '0px',
                        textAlign: 'left',
                        color: '#5A7184',
                      }}
                    >
                      Sign up now and find out what Squaddie has to offer and to keep up with the
                      latest Squaddie News
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

function BlogIndexPage() {
  return (
    <Layout>
      <GatsbySeo
        title="Blog & News | Squaddie - The Caddie for your Golf Squad"
        description="Stay up-to-date on the latest trends and news in the world of golf with our blog. From equipment reviews to course recommendations, our experts have you covered. Check out our posts now!"
        canonical="https://www.squaddie.co/blog"
      />
      <BlogJsonLd
        authorName="Squaddie"
        authorType="Organization"
        description="Stay up-to-date on the latest trends and news in the world of golf with our blog. From equipment reviews to course recommendations, our experts have you covered. Check out our posts now!"
        datePublished="2023-03-10T08:00:00+08:00"
        url="https://www.squaddie.com/blog"
      />
      <BlogPage />
    </Layout>
  );
}

export default BlogIndexPage;
